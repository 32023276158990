import ky from 'ky';
import { createJwtAuthKyHooks } from '@bucketplace/jwt-auth/ky';
import { sessionManager } from './jwt-auth';

export const clientHttp = ky.create({
  credentials: 'include',
  headers: {
    'content-type': 'application/json',
    'accept': 'application/json',
  },
  hooks: createJwtAuthKyHooks({ jwtAuth: sessionManager }),
});

export const serverHttp = ky.create({
  credentials: 'include',
  headers: {
    'content-type': 'application/json',
    'accept': 'application/json',
  },
});
