import React from 'react';
import Link from 'next/link';
import styled from '@emotion/styled';

import { MEDIA_QUERY, pxToRem } from '@bucketplace/design-system';

import { EVENT_HOST } from '../common';

interface ErrorProps {
  statusCode?: number;
}

export const Error = ({ statusCode }: ErrorProps): React.ReactElement => {
  return (
    <Container>
      <Title>{ statusCode || 'ERROR' }</Title>
      <SubTitle>죄송합니다. 오류가 발생했습니다.</SubTitle>
      <Content>
        요청하신 페이지를 처리하는 도중 예기치 못한 오류가 발생했습니다.
        <br />
        서비스 이용에 불편을 드려 대단히 죄송합니다. 최대한 빨리 문제를 해결하도록 하겠습니다.
      </Content>
      <Link href={EVENT_HOST} passHref>
        <Anchor>이벤트 홈으로 돌아가기</Anchor>
      </Link>
    </Container>
  );
};

const Container = styled.div`
  text-align: center;
  padding: ${pxToRem(40)};

  ${MEDIA_QUERY.max('mobile', `
    padding: ${pxToRem(20)};
  `)}
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.content.primary1.basic};
  font-size: ${pxToRem(100)};
  font-weight: 600;

  ${MEDIA_QUERY.max('mobile', `
    font-size: ${pxToRem(70)};
  `)}
`;

const SubTitle = styled.h2`
  font-size: ${pxToRem(24)};
  font-weight: 400;

  ${MEDIA_QUERY.max('mobile', `
    font-size: ${pxToRem(16)};
  `)}
`;

const Content = styled.p`
  font-size: ${pxToRem(16)};
  margin: ${pxToRem(30)} 0;

  ${MEDIA_QUERY.max('mobile', `
    font-size: ${pxToRem(14)};
  `)}
`;

const Anchor = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.content.primary1.basic};
  width: ${pxToRem(200)};
  height: ${pxToRem(50)};
  border-radius: ${pxToRem(10)};
  color: #FFFFFF;
  font-size: ${pxToRem(18)};
  line-height: ${pxToRem(27)};
  cursor: pointer;
  box-shadow: 0 ${pxToRem(5)} ${pxToRem(5)} 0 rgb(0 0 0 / 20%);

  &:hover{
    transform: translate(0, ${pxToRem(5)});
    box-shadow: none;
  }

  ${MEDIA_QUERY.max('mobile', `
    width: ${pxToRem(150)};
    height: ${pxToRem(38)};
    border-radius: ${pxToRem(7)};
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(21)};
  `)}
`;
