import React, { memo } from 'react';
import Head from 'next/head';

export const DefaultHead = memo((): JSX.Element => (
  <Head>
    <title key="head-title">오늘의집 이벤트</title>
    <meta charSet="utf-8" lang="ko" />
    <meta
      name="naver-site-verification"
      content="6ca7ff6f5a2c14babde6ac5aec0171afa0b76049"
    />
    <meta
      name="viewport"
      content="width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,viewport-fit=cover"
    />
    <link rel="icon" href="/favicon.ico" />
  </Head>
));
