import { createServiceDomains, Env } from '@bucketplace/config';

type LegacyEnv = Env | 'local';

/**
 * 운영 환경
 * @example local, dev, prod
 */
export const APP_PROFILE = (process.env['APP_PROFILE'] ?? 'local') as LegacyEnv;

/**
 * 앱 이름
 */
export const APP_NAME = 'event-web';

/**
 * 앱 버전
 */
export const APP_VERSION = process.env.APP_VERSION;

export const HOST = process.env.NEXT_PUBLIC_EVENT_HOST as string;

export const DOMAINS = createServiceDomains({
  env: APP_PROFILE === 'local'
    ? (HOST.match(/(dev|stage|qa)/)?.[0] ?? 'dev') as Env
    : APP_PROFILE,
  override: {
    EVENTS: APP_PROFILE === 'local' ? HOST : undefined,
  },
});
