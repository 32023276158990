import useSWR, { SWRResponse } from 'swr';

import { MenuSchemaEntry } from '@bucketplace/design-system/bds';

import { useCreateHnCMenu } from './useCreateHnCMenu';
import { getNavigationData } from 'hooks/Navigation';
import { createO2OMenu } from './createO2OMenu';
import { composeStoreFeeds, createStoreMenu } from './createStoreMenu';

const useCreateMenu = (
  navItems: unknown,
  pathname: string,
  experiments?: Record<string, string>
): MenuSchemaEntry[] => {
  const hncMenu = useCreateHnCMenu({ experiments });

  const navigationMenuList = [
    ...hncMenu,
    ...createStoreMenu(),
    ...createO2OMenu(),
  ];

  const navigationData = navigationMenuList.map((data) => {
    // hooks over `/store/*`
    if (data.href != null && data.href.endsWith('/store')) {
      return {
        ...data,
        entries: composeStoreFeeds(navItems, pathname),
      };
    }
    return data;
  });

  return navigationData;
};

export const useNavigationMenu = (
  pathname: string,
  experiments?: Record<string, string>
): SWRResponse<MenuSchemaEntry[], Error> => {
  const { data, ...res } = useSWR<MenuSchemaEntry[], Error>(
    ['navigation', 'menu'],
    () => getNavigationData()
  );

  const combined = useCreateMenu(data, pathname, experiments);

  return { ...res, data: combined };
};
