import React, { createContext, useContext, useState, useEffect, useLayoutEffect, useRef, useCallback, useMemo } from 'react';
import { NextRouter, useRouter } from 'next/router';
import { camelizeKeys } from 'humps';

import { PCTopBannerContent } from '@bucketplace/design-system/bds';
import { logPageviewAfterLoad, logPageviewBeforeLoad } from '@bucketplace/bucket-log';
import { NavigationResponse, User } from '@bucketplace/common';

import { BannerType, EventUserProfileType, NavigationDataType } from 'types';
import { getNavigationData } from 'hooks';
import { airbridgeLogout } from 'utils';
import { clientHttp } from 'common/http';

interface UserInfoType {
  user: EventUserProfileType | undefined;
  cartCount? : number;
  notificationCount?: number;
}

interface UserContextType {
  userInfo? : UserInfoType;
  rawLoginUser?: User;
  appBanner? : BannerType | null;
  pcBanner? : PCTopBannerContent[] | null;
  pending?: boolean;
  nav?: NavigationResponse;
  signOut? : () => void;
}

const UserContext = createContext<UserContextType>({});

export const UserContextProvider = ({ children }: {
  children: React.ReactElement,
}): React.ReactElement => {
  const router = useRouter();
  const prevLocationRef = useRef<NextRouter['asPath'] | null>(null);
  const [userInfo, setUserInfo] =
    useState<UserInfoType | undefined>(undefined);
  const [pending, setPending] = useState(!userInfo);
  const [appBanner, setAppBanner] = useState<BannerType | null>(null);
  const [pcBanner, setPcBanner] = useState<PCTopBannerContent[] | null>(null);
  const [nav, setNav] = useState<NavigationResponse | null>(null);
  const [rawLoginUser, setRawLoginUser] = useState<User | null>(null);

  const fetchNavigationData = useCallback((): void => {
    setPending(true);
    getNavigationData()
      .then((data) => {
        return convertNavigationData(data);
      }).then((data: NavigationDataType) => {
        const userId = data?.loginUser?.userId;
        (window as any).bucketLog.push({ user_id: userId || null });
        setUserInfo({
          user: data?.loginUser,
          cartCount: data?.cartCount,
          notificationCount: data?.unseenNotification,
        });
        setRawLoginUser(data.rawLoginUser);
        setAppBanner(data?.appDownloadBanner);
        setPcBanner(data?.storePcTopBanner?.contents);
        setNav(data?.nav);
      }).finally(() => {
        setPending(false);
      });
  }, []);

  useLayoutEffect(() => {
    if (userInfo == null) {
      fetchNavigationData();
    }
  }, [userInfo, fetchNavigationData]);
  useEffect(() => {
    if (userInfo != null) {
      router.events.on('routeChangeComplete', logPageviewAfterLoad);
    }
    return () => router.events.off('routeChangeComplete', logPageviewAfterLoad);
  }, [userInfo, router.events]);
  useEffect(() => {
    if (userInfo == null) return;
    if (prevLocationRef.current == null) {
      prevLocationRef.current = router.asPath;
      return;
    }
    const prevPath = prevLocationRef.current;
    const currPath = router.asPath;
    if (prevPath !== currPath) {
      prevLocationRef.current = currPath;
      logPageviewBeforeLoad(prevPath, currPath);
    }
  }, [prevLocationRef, router, userInfo]);
  const signOut = useCallback(async () => {
    const json = await clientHttp.delete('/api/navigation/signOut').json<any>();
    if (!json.success) {
      return;
    }
    fetchNavigationData();
    (window as any).bucketLog.push({ user_id: null });
    airbridgeLogout();
  }, [fetchNavigationData]);
  const convertNavigationData = (navigationData): NavigationDataType => {
    navigationData = camelizeKeys(navigationData);
    const { loginUser } = navigationData;
    return {
      ...navigationData,
      rawLoginUser: loginUser,
      loginUser: loginUser ?
        {
          userId: loginUser.id,
          isAdmin: loginUser.isAdmin,
          imageUrl: loginUser.profileImageUrl,
          nickname: loginUser.nickname,
          userableType: loginUser.userableType,
        } :
        null,
    };
  };

  const userValue = useMemo(() => ({
    userInfo,
    appBanner,
    pcBanner,
    pending,
    signOut,
    nav,
    rawLoginUser,
  }), [
    appBanner,
    pcBanner,
    pending,
    signOut,
    userInfo,
    nav,
    rawLoginUser,
  ]);
  return (
    <UserContext.Provider value={userValue}>
      { children }
    </UserContext.Provider>
  );
};

export const useUserContext = (): UserContextType => {
  const context = useContext(UserContext);
  return context;
};
