import * as querystring from 'querystring';

import {
  Icon,
  MenuSchemaEntry,
  StoreCategory,
  StoreCategoryProps,
} from '@bucketplace/design-system/bds';

import { StoreFeed } from 'types';
import { OHS_HOST, STORE_HOST } from 'common';

export const createStoreMenu = (): MenuSchemaEntry[] => {
  return [
    {
      title: '쇼핑',
      icon: <Icon size={24} name="store_outline" />,
      href: `${OHS_HOST}/store`,
      active: true,
      entries: [],
    },
  ];
};

const STORE_FEEDS = {
  StoreWedding: 'StoreWeddingFeed',
  StoreCustom: 'StoreCustomFeed',
  Store: 'StoreFeed',
} as const;
type Feeds = typeof STORE_FEEDS[keyof typeof STORE_FEEDS];

const getFeeds = (
  storeFeeds: StoreFeed[],
  type: Feeds
): StoreFeed | StoreFeed[] | undefined => {
  switch (type) {
    case STORE_FEEDS.StoreWedding:
      return storeFeeds.find((feed) => feed.type === STORE_FEEDS.StoreWedding);
    case STORE_FEEDS.StoreCustom:
      return storeFeeds.find((feed) => feed.type === STORE_FEEDS.StoreCustom);
    case STORE_FEEDS.Store:
      return storeFeeds.filter((feed) => (
        feed.type === STORE_FEEDS.Store
      ));
    default:
      return undefined;
  }
};

export const composeStoreFeeds = (data: any, pathname: string): MenuSchemaEntry[] => {
  const storeFeeds = data?.storeFeeds ?? [];
  const weddingItem =
    getFeeds(storeFeeds, STORE_FEEDS.StoreWedding) as StoreFeed | undefined;
  const customFeedItem =
    getFeeds(storeFeeds, STORE_FEEDS.StoreCustom) as StoreFeed | undefined;
  const specialFeedItems = getFeeds(storeFeeds, STORE_FEEDS.Store) as StoreFeed[];
  const storeHamburgerItems: StoreCategoryProps['categories']
    = (data?.storeHamburger?.categories ?? [])
      .map(({ id, title, hash, count, imageUrl }) => {
        const queryString = querystring.stringify({
          affect_type: 'StoreHamburger',
          category: hash,
        });

        return {
          title,
          hash,
          count,
          imageUrl,
          id: +id,
          to: `${OHS_HOST}/store/category?${queryString}`,
        };
      });

  return [
    {
      title: '쇼핑홈',
      href: `${OHS_HOST}/store`,
    },
    {
      title: '카테고리',
      href: `${OHS_HOST}/store/category?category=0&order=popular`,
      renderBarChild: () => (
        <StoreCategory
          categories={storeHamburgerItems}
        />
      ),
    },
    {
      title: '베스트',
      href: `${OHS_HOST}/commerces/ranks`,
    },
    ...specialFeedItems.map((item) => {
      const match = /(https?:\/\/[^/]+)(\/[^?]+)(\?[^#]+)?(#.+)?$/
        .exec(item.content.url);

      if (match == null) {
        return null;
      }

      const newUrl = `${match[1]}${match[2]}${match[3] ? `${match[3]}&` : '?'}special_feed=true`;

      // in events.ohou.se, special_feed must be active even when the first segment of full path matches
      const [, firstSegment] = /^(\/[^/]*)/.exec(pathname) ?? [];
      return {
        title: item.feedTitle,
        bullet: item.updateAlert ? 'new' : undefined,
        href: match[2].includes('today_deals') ? `${OHS_HOST}${match[2]}` : newUrl,
        active: match[2].startsWith(firstSegment),
      };
    }),
    weddingItem != null
      ? {
        title: weddingItem.feedTitle ?? '프리미엄',
        bullet: weddingItem.updateAlert ? 'new' : undefined,
        href: `${OHS_HOST}/productions/premium`,
      }
      : null,
    {
      title: '기획전',
      href: `${STORE_HOST}/exhibitions?showroom=false`,
    },
    customFeedItem != null
      ? {
        title: customFeedItem.feedTitle,
        bullet: customFeedItem.updateAlert ? 'new' : undefined,
        href: `${OHS_HOST}/commerces/custom_feeds/feed?logic=0`,
      }
      : null,
  ].filter(Boolean) as MenuSchemaEntry[];
};
