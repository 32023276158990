interface ShareParams {
  data?: ShareData;
  fallback?: () => void;
}

/**
 * Web Share API를 사용하여 시스템 공유창을 띄웁니다.
 * @param {object} data ShareData(lib.dom.d.ts)
 * @param {function} fallback Web Share API가 지원되지 않는 환경인 경우의 fallback
 * @return {Promise} Promise&lt;void&gt;
 */
export const share = async ({
  data = {},
  fallback,
}: ShareParams): Promise<void> => {
  if (navigator.share) {
    try {
      await navigator.share({
        title: data.title || document?.title,
        ...data,
      });
    } catch (e) {
      console.error(e);
    }
  } else {
    fallback?.();
  }
};
