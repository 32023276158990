import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { ScrollLock } from '@bucketplace/ohouse-ui';
import { FONT_STYLE } from '@bucketplace/design-system';
import { Modal } from '@bucketplace/design-system/bds';

export interface ConfirmModalProps {
  message: string;
  onConfirm: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
  cancelable?: boolean;
  confirmText?: string;
  secondaryText?: string;
}

export function ConfirmModal({
  message,
  onConfirm,
  onCancel,
  cancelable,
  confirmText,
  secondaryText = '취소',
}: ConfirmModalProps): React.ReactElement {
  let secondaryAction = {};
  if (cancelable) {
    secondaryAction = {
      secondary: {
        onClick: onCancel,
        text: secondaryText,
      },
    };
  }

  return (
    <>
      <ScrollLock enabled />
      <Modal
        css={css`
        z-index: 1300; // 레거시 Modal 컴포넌트와 겹쳐 생성될 경우 zindex 우선순위를 높이기 위해 설정
      `}
        width={300}
        isPopupInMobile
        footer={{
          action: {
            primary: {
              onClick: onConfirm,
              text: confirmText ?? '확인',
            },
            ...secondaryAction,
          },
        }}
      >
        <Content>
          { message }
        </Content>
      </Modal>
    </>
  );
}

const Content = styled.p`
  ${FONT_STYLE.body1_1};
  color: ${({ theme }) => theme.content.base1};
  padding: 16px 16px 10px 16px;
  text-align: center;
  word-break: keep-all;
  white-space: pre-line;
`;
