import * as querystring from 'querystring';
import { isSSR } from './isSSR';

export const saveAppOsInfo = (): void => {
  const search = querystring.parse(location.search.slice(1));

  try {
    localStorage.os_type = search.os_type;
    localStorage.os_version = search.version;
  } catch (err) {
    //
  }
};

// TODO: Type 구체화
export const getAppOsInfo = (): {
  osType: string,
  osVersion: string,
} | null => {
  if (isSSR) {
    return null;
  }

  const search = querystring.parse(location.search.slice(1));
  let osType = null;
  let osVersion = null;

  if (search.os_type != null) {
    osType = search.os_type;
    localStorage.os_type = search.os_type;
  } else {
    osType = localStorage.os_type;
  }
  if (search.version != null) {
    osVersion = search.version;
    localStorage.os_version = search.version;
  } else {
    osVersion = localStorage.os_version;
  }

  return {
    osType,
    osVersion,
  };
};
