import React, { MutableRefObject } from 'react';
import styled from '@emotion/styled';

import { createOhouseFooterLinkSchema, OhouseFooter } from '@bucketplace/design-system/bds';

import { DOMAINS, OHS_HOST } from 'common';
import { isInApp } from 'utils';

interface FooterContainerProps {
  footerRef: MutableRefObject<HTMLDivElement>;
}

export const FooterContainer = ({ footerRef }: FooterContainerProps): React.ReactElement | null => {
  if (isInApp()) return null;
  return (
    <Container ref={footerRef}>
      <OhouseFooter ohouseFooterLinks={createOhouseFooterLinkSchema({ ohs: OHS_HOST, pro: DOMAINS.O2O_PARTNER })} />
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`;
