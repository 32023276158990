import useSWR, { SWRResponse } from 'swr';
import { clientHttp } from 'common/http';

const getNowTime = async (): Promise<Date> => {
  const json = await clientHttp.get('/api/time').json<string>();
  const nowDate = new Date(json);

  return nowDate;
};

export const useNowTime = (): SWRResponse<Date, Error> => {
  return useSWR<Date, Error>(
    ['date'],
    getNowTime
  );
};
