import { useEffect, useRef } from 'react';

export const useInterval = (callback: () => void, delay : number): void => {
  const savedCallback = useRef(null);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    const tick = (): void => {
      savedCallback.current();
    };
    let intervalHandle: number | undefined;
    if (delay !== null) {
      intervalHandle = window.setInterval(tick, delay);
    }
    return () => {
      if (intervalHandle != null) {
        clearInterval(intervalHandle);
      }
    };
  }, [delay]);
};
