
import * as querystring from 'querystring';
import { pick, isEmpty } from 'lodash';
import { getPageVariable } from '@bucketplace/bucket-log';

const QUERY_BLACKLIST = [
  'airbridge_referrer',
  'fallback_ios',
  'fallback_android',
  'short_id',
  'https_deeplink',
  'campaign',
  'ad_group',
  'ad_creative',
  'term',
  'deeplink_url',
  'fallback_desktop',
  'n_media',
  'n_query',
  'n_rank',
  'n_ad_group',
  'n_ad',
  'n_keyword_id',
  'n_keyword',
  'n_campaign_type',
  'n_ad_group_type',
  'NaPm',
  'routing_short_id',
  'content',
  'sub_id_1',
  'sub_id_2',
  'sub_id_3',
  'campaign_id',
  'ad_group_id',
  'ad_creative_id',
  'term_id',
];

const UTM_TAG_LIST = ['source', 'utm_source', 'utm_campaign', 'utm_content',
  'utm_media', 'utm_term', 'utm_medium', 'bp_adset', 'bp_content',
  'bp_sub_id', 'bp_sub_id_1', 'bp_sub_id_2'];

type UtmDataKey = typeof UTM_TAG_LIST[number];
export type UtmData = Partial<Record<UtmDataKey, string>>;

export function memoize<T extends (...args: any[]) => any>(func: T): T {
  let prevArgs: any[] = null;
  let prevResult: ReturnType<T> = null;
  return ((...args: any[]) => {
    if (
      prevArgs == null ||
      prevArgs.length !== args.length ||
      prevArgs.some((v, i) => v !== args[i])
    ) {
      prevArgs = args;
      prevResult = func(...args);
    }
    return prevResult;
  }) as T;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function omitKeys<T extends object, K extends keyof T> (
  obj: T, keys: K[]
): Pick<T, Exclude<keyof T, K>> {
  const output: any = {};
  Object.keys(obj)
    .filter((k) => !(keys as string[]).includes(k))
    .forEach((k) => output[k] = obj[k]);
  return output as Pick<T, Exclude<keyof T, K>>;
}

export function filter<T> (
  obj: T,
  cb: (v: any, k: keyof T) => boolean
): Partial<T> {
  const output: Partial<T> = {};
  for (const k in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, k) && cb(obj[k], k)) {
      output[k] = obj[k];
    }
  }
  return output;
}

export const getQueryString = (query: { [key: string]: any }): string => {
  return querystring.stringify(query);
};

export const getQuery = memoize((url: string): { [key: string]: string } => {
  const search = (/\?(.+)$/.exec(url) || ['', ''])[1];
  return querystring.parse(search.replace(/&amp;/g, '&')) as {
    [key: string]: string,
  };
});

/**
 * 쿼리 문자열에서 utm 파라미터 객체를 추출합니다.
 * @param {string} queryString utmData를 추출하고픈 target 쿼리 문자열
 * @return {object} 추출된 utm 파라미터 객체
 */
export const getUtmDataFromUrl = (queryString?: string): UtmData => {
  const query = getQuery(queryString ?? location.search);
  const utmQuery = pick(query, UTM_TAG_LIST);
  return escapeBracesInQuery(utmQuery);
};

export const getUtmData = () : UtmData => {
  try {
    return JSON.parse(sessionStorage['ohsUtmData'] || '{}');
  } catch (e) {
    //
    return {};
  }
};

/**
 * 현재 url의 utmData를 추출하여 sessionStorage에 저장합니다.
 */
export const setUtmData = (): void => {
  const urlData = getUtmDataFromUrl();
  if (isEmpty(urlData)) return;
  try {
    sessionStorage.setItem('ohsUtmData', JSON.stringify(urlData));
  } catch (e) {
    //
  }
};

// eslint-disable-next-line @typescript-eslint/ban-types
function escapeBracesInQuery<T extends object> (
  query: T
): Partial<T> {
  const trimmed = Object.fromEntries(
    Object.entries(query).map(([key, value]) => {
      return [key, value?.replace(/([\\_]?{[^}]+})+/g, '')];
    })
  );
  return filter(trimmed, (v) => v != null && v !== '') as Partial<T>;
}

export const getDeepLink = (
  additionalParams?: { [key: string]: string },
  pathname?: string,
  query?: string
): string => {
  const queryObj = getQuery(query ?? location.search);
  const essentialQuery = filter({
    ...omitKeys(
      queryObj,
      [...UTM_TAG_LIST, ...QUERY_BLACKLIST]
    ),
  }, (v) => v != null);
  const escapedQuery = escapeBracesInQuery(essentialQuery);
  const searchString = querystring.stringify({
    ...escapedQuery,
    click_action_type: 'webview',
  });
  const path = `/events${pathname ?? location.pathname}?${searchString}`;
  const utmData = getUtmData();

  // const isProd = process.env.APP_PROFILE === 'prod';
  // const host = isProd ? 'ohou.se': 'stage-web.dailyhou.se';

  return `https://link.ohou.se/@ohouse/${utmData['utm_source'] || 'web_organic'}?` + querystring.stringify({
    deeplink_url: `ohouseapp://ohou.se${path}`,
    fallback_desktop: `https://ohou.se${path}`,
    campaign: queryObj.campaign || utmData['utm_campaign'],
    term: queryObj.term || utmData['utm_term'],
    client_id: getPageVariable('visitor_id'),
    sub_id: queryObj.sub_id || utmData['bp_sub_id'],
    sub_id_1: 'ohouseWeb',
    sub_id_2: queryObj.sub_id_2 || utmData['bp_sub_id_2'],
    sub_id_3: queryObj.sub_id_3 || utmData['utm_medium'],
    ad_creative: queryObj.ad_creative || utmData['utm_content'],
    ad_group: queryObj.ad_group || utmData['bp_adset'],
    content: queryObj.content || utmData['bp_content'],
    ...additionalParams ?? {},
  });
};

export interface AribridgeLogProps {
  category: string;
  attribute?: {
    label?: string,
    action?: string,
    value?: number,
    semanticAttributes?: Record<string, unknown>,
    customAttributes?: Record<string, unknown>,
  };
}

export const airbridgeLogout = (): void => {
  sendAirbridgeLog({
    category: 'airbridge.user.signout',
  });
  (window as any)?.airbridge?.clearUser();
};

export const sendAirbridgeLog = ({
  category,
  attribute,
}: AribridgeLogProps): void => {
  (window as any)?.airbridge?.events?.send(
    category,
    attribute
  );
};

const KEYWORD_INFLOW_PATH_LIST = [
  'google',
  'brand_google',
  'naver',
  'brand_naver',
  'daum',
  'brand_daum',
];

const SHOPPING_INFLOW_PATH_LIST = [
  'naver_shop',
  'google_shop',
  'enuri_shop',
  'googleadwords_int',
];

export const sendA1MediaInflow = (): void => {
  const utmData = getUtmData();
  if (KEYWORD_INFLOW_PATH_LIST.includes(utmData?.utm_source)) {
    (window as any)?.linkback?.send?.('inflow');
  } else if (SHOPPING_INFLOW_PATH_LIST.includes(utmData?.utm_source)) {
    (window as any)?.linkback?.track?.('inflow');
  }
};
