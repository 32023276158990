import React, { useState, createContext, useContext, Dispatch, useEffect, useMemo } from 'react';

import { throttle } from 'utils';

interface ScrollContextProps {
  scrollPosition: number;
  activeCategory: number;
  setActiveCategory: Dispatch<number>;
  stickyHeight: number;
  setStickyHeight: Dispatch<number>;
}

const ScrollContext = createContext<ScrollContextProps>({
  scrollPosition: 0,
  activeCategory: 0,
  setActiveCategory: () => {/* noop */},
  stickyHeight: 0,
  setStickyHeight: () => {/* noop */},
});

export const ScrollProvider: React.FC = ({ children }): React.ReactElement => {
  const [scrollPosition, setScrollPosition] = useState<number>(-1);
  const [activeCategory, setActiveCategory]= useState<number>(0);
  const [stickyHeight, setStickyHeight]= useState<number>(0);

  useEffect(() => {
    const timerId: number | null = null;

    const handleScroll = (): void => {
      if (timerId != null) {
        cancelAnimationFrame(timerId);
      }
      setScrollPosition(Math.floor(window.pageYOffset));
    };

    const throttleHandleScroll = throttle(handleScroll, 100);

    document.addEventListener('scroll', throttleHandleScroll, { passive: true });

    return () => {
      if (timerId != null) {
        cancelAnimationFrame(timerId);
      }
      document.removeEventListener('scroll', throttleHandleScroll);
    };
  }, []);

  const scrollValue = useMemo(() => ({
    scrollPosition: scrollPosition,
    activeCategory: activeCategory,
    setActiveCategory: setActiveCategory,
    stickyHeight: stickyHeight,
    setStickyHeight: setStickyHeight,
  }), [activeCategory, scrollPosition, stickyHeight]);

  return (
    <ScrollContext.Provider value={scrollValue}>
      { children }
    </ScrollContext.Provider>
  );
};

export const useScrollContext = (): ScrollContextProps => {
  const context = useContext(ScrollContext);
  return context;
};
