import * as querystring from 'querystring';
import { useMediaQuery } from 'react-responsive';
import { NextParsedUrlQuery } from 'next/dist/server/request-meta';
import UAParser from 'ua-parser-js';

import { DeviceType, OSType } from 'types';
import { isSSR } from './isSSR';

export const isMobile = (): boolean => {
  if (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) {
    return true;
  }
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
};

export const isInApp = (query?: { [key: string]: any }): boolean => {
  if (isSSR) {
    if (query != null) {
      return query.os_type != null || query.app != null;
    }
    return false;
  }

  let triggered = false;
  try {
    triggered = sessionStorage.webviewTriggered === 'yes';
  } catch (e) {
    //
  }

  const search = querystring.parse(location.search.slice(1));

  if (
    triggered ||
      (search.os_type != null && search.os_type !== '') ||
      search.app != null
  ) {
    try {
      sessionStorage.webviewTriggered = 'yes';
    } catch (e) {
      //
    }
    return true;
  }
  return false;
};

export const useDetectDevice = () : DeviceType => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 768px)',
  });

  if (isInApp()) {
    return 'mobile';
  }
  if (isDesktopOrLaptop) {
    return 'web';
  }
  return 'mobileWeb';
};

export const getOSType = (query: NextParsedUrlQuery) : OSType => {
  if (isSSR) return 'WEB';

  const osType = query?.os_type as string;
  const osTypeParams = osType?.toUpperCase() as OSType;

  const userAgent = navigator?.userAgent;
  const parser = new UAParser(userAgent);
  const result = parser.getResult();

  const isApp = osTypeParams && ['ANDROID', 'IOS'].includes(osTypeParams);

  if (isApp) {
    return osTypeParams;
  } else if (result.device.type) {
    return result.os.name === 'iOS' ? 'MWEBIOS' : 'MWEBANDROID';
  } else {
    return 'WEB';
  }
};

export const getOSTypeSSR = (req): OSType => {
  const params = new URLSearchParams(req?._parsedUrl?.query);
  const osTypeParams = params.get('os_type')?.toUpperCase() as OSType;
  const userAgent = req?.headers['user-agent'];
  const parser = new UAParser(userAgent);
  const result = parser.getResult();

  const isApp = osTypeParams && ['ANDROID', 'IOS'].includes(osTypeParams);

  if (isApp) {
    return osTypeParams;
  } else if (result.device.type) {
    return result.os.name === 'iOS' ? 'MWEBIOS' : 'MWEBANDROID';
  } else {
    return 'WEB';
  }
};

export const getDeviceType = (osType: OSType): DeviceType => {
  switch (osType) {
    case 'IOS':
    case 'ANDROID':
      return 'mobile';
    case 'MWEBANDROID':
    case 'MWEBIOS':
      return 'mobileWeb';
    case 'WEB':
      return 'web';
  }
};

export const detectDeviceSSR = (req): DeviceType => {
  const osType = getOSTypeSSR(req);
  return getDeviceType(osType);
};
