export type QueryPlatformType =
  'Android' | 'iOS' | 'mWebIOS' | 'mWebAndroid' | 'Web' | null;
export type ABPlatformType = 'web' | 'mweb' | 'ios' | 'aos';

export const formatOsType = (osType: QueryPlatformType): ABPlatformType => {
  switch (osType) {
    case 'Android':
      return 'aos';
    case 'iOS':
      return 'ios';
    case 'mWebIOS':
    case 'mWebAndroid':
      return 'mweb';
    default:
      return 'web';
  }
};

interface AppVersion {
  osType?: string;
  currentVersion?: string | null;
  androidVersion: string;
  iosVersion: string;
}

export const checkAppVersion = ({
  osType = 'Web',
  currentVersion,
  androidVersion,
  iosVersion,
}: AppVersion): boolean => {
  let targetSelected;
  if (osType === 'Android') {
    targetSelected = androidVersion;
  } else if (osType === 'iOS') {
    targetSelected = iosVersion;
  } else {
    return true;
  }

  if (currentVersion == null) {
    return true;
  }

  const versionFragments = currentVersion.split('.');
  const targetFragments = targetSelected.split('.');

  for (let i = 0; i < targetFragments.length; i += 1) {
    const targetFragment = parseInt(targetFragments[i], 10);
    const versionFragment = parseInt(versionFragments[i] ?? '0', 10);
    if (targetFragment > versionFragment) {
      return false;
    } else if (targetFragment < versionFragment) {
      return true;
    }
  }
  return true;
};
