import { useEffect } from 'react';
import { useInView, InViewHookResponse } from 'react-intersection-observer';

import { bucketLog, BucketLogData, formattingLogData } from '@bucketplace/bucket-log';

interface UseImpressionLogProps {
  logData?: BucketLogData;
  threshold?: number;
  disable?: boolean;
}

interface UseImpressionLog {
  ref: InViewHookResponse['ref'];
}

export function useImpressionLog ({
  logData,
  threshold,
  disable,
}: UseImpressionLogProps): UseImpressionLog {
  const { ref, inView } = useInView({
    threshold,
    skip: disable,
    triggerOnce: true,
  });
  useEffect(() => {
    if (inView && !disable && !!logData) {
      bucketLog({
        category: 'impression',
        data: [{
          ...formattingLogData(logData),
          time: Date.now(),
        }],
      });
    }
  }, [inView, disable, logData]);
  return {
    ref,
  };
}
