import { bucketLog } from '@bucketplace/bucket-log';
import { NavigationTopBannerLoggingType } from '@bucketplace/design-system/bds';

import { setCookie } from '../cookie';
import { getDeepLink } from '../tracking';

const LOG_MAP = {
  'banner.top.pc.click': {
    bucket: { section: '네비 배너' },
  },
  'banner.top.pc.close': {
    bucket: { section: '네비 배너 닫기' },
    cookie: 'ohsTopBannerClosed',
  },
  'banner.top.mobile.click': {
    bucket: { section: '네비 배너' },
  },
  'banner.top.mobile.close': {
    bucket: { section: '네비 배너 닫기' },
    cookie: 'ohsTopBannerClosed',
  },
  'banner.bottom.mobile.click': {
    bucket: { section: '앱 다운로드' },
  },
  'banner.bottom.mobile.close': {
    bucket: { section: '앱 다운로드 닫기' },
    cookie: 'ohsAppDownloadPopupClosed',
  },
};

interface LogType {
  bucket: {
    section: string,
    index?: number,
  };
  cookie?: string;
}

export const handleLog = (type: NavigationTopBannerLoggingType, index?: number): void => {
  let { bucket, cookie } : LogType = LOG_MAP[type];
  if (index !== undefined) {
    bucket = {
      ...bucket,
      index,
    };
  }

  if (bucket) {
    bucketLog({
      category: 'click',
      ...bucket,
    });
  }

  if (cookie) {
    setCookie(cookie, 'yes', 24*3600*1000);
  }
};

const APP_OPEN_MAP = {
  'banner.bottom.mobile.click': {
    sub_id_1: 'web_bottom_bnr',
  },
  'banner.top.mobile.click': {
    sub_id_1: 'web_top_bnr',
  },
};

export const handleOpenApp = (type: NavigationTopBannerLoggingType) : void => {
  window.location.href = getDeepLink(APP_OPEN_MAP[type]);
};

