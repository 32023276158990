import { NextRouter } from 'next/router';
import { useEffect, useState } from 'react';

function parseHash (url: string): string {
  const [, segment] = url.split('#');
  return segment != null ? `#${segment}` : '';
}

export function useHash (router: NextRouter): string {
  // a hash should always result in empty string ('') as an initial value in
  // both server/client environment or you will get hydration errors.
  const [hash, setHash] = useState<string>('');
  useEffect(() => {
    const handleHashChangeStart = (url: string): void => {
      setHash(parseHash(url));
    };
    const handleHashChange = (e: HashChangeEvent): void => {
      setHash(parseHash(e.newURL));
    };
    router.events.on('hashChangeStart', handleHashChangeStart);
    window.addEventListener('hashchange', handleHashChange);
    setHash(parseHash(router.asPath));
    return () => {
      router.events.off('hashChangeStart', handleHashChangeStart);
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [router]);
  return hash;
}
