import { useMemo } from 'react';
import {
  BreakPoint,
  useMatchMedia,
  UseMatchMediaOptions,
} from '@bucketplace/design-system';

import { createMediaBreakQuery } from 'utils/media';

export function useResponsiveQuery (
  type: 'min' | 'max' | 'only',
  breakpoint: BreakPoint,
  useMatchMediaOptions?: UseMatchMediaOptions
): boolean {
  const query = useMemo(() => {
    return createMediaBreakQuery(type, breakpoint);
  }, [type, breakpoint]);
  const { matches } = useMatchMedia(query, useMatchMediaOptions);
  return matches ?? false;
}
