import React, {
  useState,
  createContext,
  useContext,
  Dispatch,
  useMemo,
} from 'react';

interface TabContextProps {
  currentMainId: string;
  setCurrentMainId: Dispatch<string>;
}

const TabContext = createContext<TabContextProps>({
  currentMainId: '',
  setCurrentMainId: () => {/* noop */},
});

interface TabDataType {
  id: string;
  label: string;
  subLabel?: string;
}

interface TabItemType extends TabDataType {
  subTab?: TabDataType[];
}

interface TabProviderProps {
  children: React.ReactNode;
  tabList: TabItemType[];
}

export const TabProvider = ({ children, tabList }: TabProviderProps): React.ReactElement => {
  const [currentMainId, setCurrentMainId] = useState<string>(tabList[0]['id']);

  const tabValue = useMemo(() => ({
    currentMainId: currentMainId,
    setCurrentMainId: setCurrentMainId,
  }), [currentMainId]);

  return (
    <TabContext.Provider value={tabValue}>
      { children }
    </TabContext.Provider>
  );
};

export const useTabContext = (): TabContextProps => {
  return useContext(TabContext);
};
