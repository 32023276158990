import React from 'react';
import { useRouter } from 'next/router';
import { NavigationTopBanner } from '@bucketplace/design-system/bds';
import { Env, GNB } from '@bucketplace/common';

import { useUserContext } from 'provider';

import { getQueryString, isInApp, handleOpenApp, handleLog, getDeepLink } from 'utils';

import { SuperLink } from '../Link/SuperLink';

export const Header = (): React.ReactElement => {
  const { userInfo, pcBanner, nav, rawLoginUser } = useUserContext();
  const router = useRouter();
  const { pathname, query } = router;
  const search = `?${getQueryString(query)}`;

  return (
    <>
      { pcBanner && (
        <NavigationTopBanner
          location={{
            pathname,
            search,
          }}
          pcContentList={pcBanner}
          linkComponent={SuperLink}
          onAppOpen={handleOpenApp}
          onLog={handleLog}
          isUserLoggedIn={!!userInfo.user}
        />
      ) }
      { !isInApp() && (
        <GNB
          linkComponent="a"
          location={{ pathname, search, origin: process.env.NEXT_PUBLIC_EVENT_HOST, hash: '' }}
          env={process.env.APP_PROFILE as Env}
          subdomain="events"
          menus={nav?.menus}
          supplementary={nav?.supplementary}
          searchMenus={nav?.searchMenus}
          trendingKeywords={nav?.trendingKeywords}
          getDeepLink={getDeepLink}
          user={rawLoginUser}
        />
      ) }
    </>
  );
};
