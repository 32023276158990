import { OSType } from 'types';
import { clientHttp } from 'common/http';

export interface handleClickLogParams {
  osType: OSType;
  eventId: string;
  promotionId: number;
  type: string;
}

export const handleClickLog = async ({
  osType,
  eventId,
  promotionId,
  type,
}: handleClickLogParams): Promise<void> => {
  try {
    await clientHttp.put('/api/campaign/raffle/clicks', {
      json: {
        promotionId,
        eventId,
        platform: osType,
        type,
      },
    });
  } catch (error) {
    // noop
  }
};
