export const EVENT_SELF_PORT = typeof window === 'undefined' ? process.env.PORT || '3000' : '';

export const OHS_HOST = process.env.NEXT_PUBLIC_OHS_HOST || 'https://ohou.se';
export const EVENT_HOST = process.env.NEXT_PUBLIC_EVENT_HOST || 'https://events.ohou.se';
export const STORE_HOST = process.env.NEXT_PUBLIC_STORE_HOST || 'https://store.ohou.se';
export const O2O_HOST = process.env.NEXT_PUBLIC_O2O_HOST || 'https://o2o.ohou.se';
export const PRO_HOST = process.env.NEXT_PUBLIC_PRO_HOST || 'https://pro.ohou.se';
export const CONTENT_HOST = process.env.NEXT_PUBLIC_CONTENT_HOST || 'https://contents.ohou.se';
export const PARTNERS_HOST = 'https://partners.ohou.se';
export const EVENT_SELF_HOST = process.env.NODE_ENV === 'development'
  ? EVENT_HOST
  // in production, we need to use localhost as a host to prevent long network hops
  : `http://localhost${EVENT_SELF_PORT !== '' ? `:${EVENT_SELF_PORT}` : ''}`;

export const API_GATEWAY_HOST = process.env.NEXT_PUBLIC_API_GATEWAY_HOST || 'https://api.ohou.se';

export const SIGN_IN_URL = `${OHS_HOST}/users/sign_in?redirect_to=/events`;
