import { useEffect, useMemo, useState } from 'react';

import {
  Badge,
  Icon,
  MenuSchemaEntry,
} from '@bucketplace/design-system/bds';

import { Topic } from 'types';
import { OHS_HOST, CONTENT_HOST } from 'common';
import { CONTENTS_EXPERIMENT_TYPE } from 'components/Layout';
import { getNavigationHnCData } from './getNavigationData';

interface UseCreateHnCMenuParams {
  experiments?: Record<string, string>;
}

interface Topics {
  default: Topic;
  fixed: Topic[];
}

export const useCreateHnCMenu = ({ experiments }: UseCreateHnCMenuParams): MenuSchemaEntry[] => {
  const [topics, setTopics] = useState<Topics>({ default: { key: '', name: '' }, fixed: [] });

  const experimentType = experiments?.contents;

  const formatTopics = (topics: Topic[]): Topics => {
    /** recommendTopic 찾는 순서
     * 1. defaultTopic = true
     * 2. key = 'recommend'(DEFAULT_TOPIC_KEY)
     * 3. topics 중 첫번째 item
     */
    const recommendTopic = topics.find(({ defaultTopic, key }) => defaultTopic || key === 'recommend') ?? topics[0];
    const lifeTopics = topics.filter((topic) => topic !== recommendTopic);

    return {
      default: recommendTopic,
      fixed: lifeTopics,
    };
  };

  const createTopicMenu = (topics: Topic[]): MenuSchemaEntry[] => {
    return topics.map(({ name, key }) => ({
      title: `${name}`,
      href: `${CONTENT_HOST}/topics/${key}`,
      logId: key,
    }));
  };

  const legacyMenu = useMemo(() => ([
    {
      title: '커뮤니티',
      icon: <Icon size={24} name="home_outline" />,
      href: `${OHS_HOST}`,
      entries: [
        {
          title: '홈',
          href: `${OHS_HOST}/`,
        },
        {
          title: '팔로잉',
          href: `${OHS_HOST}/contents/follow/feed`,
        },
        {
          title: '사진',
          href: `${OHS_HOST}/contents/card_collections`,
        },
        {
          title: '집들이',
          href: `${OHS_HOST}/projects?writer=self`,
        },
        {
          title: '노하우',
          href: `${OHS_HOST}/advices`,
        },
        {
          title: '전문가집들이',
          href: `${OHS_HOST}/projects?writer=pro`,
        },
        {
          title: '셀프가이드',
          href: `${OHS_HOST}/advices/guides/self_interior`,
        },
        {
          title: '3D인테리어',
          href: `${OHS_HOST}/interior3ds`,
          badge: {
            text: 'BETA',
            type: 'fill',
            variant: 'base2',
          } as Badge,
        },
        {
          title: '이벤트',
          href: `${OHS_HOST}/competitions/feed`,
        },
      ].filter(Boolean),
    },
  ].filter(Boolean)), []);

  const newMenu = useMemo(() => ([
    {
      title: '커뮤니티',
      icon: <Icon size={24} name="home_outline" />,
      href: `${OHS_HOST}`,
      entries: [
        {
          title: '홈',
          href: `${OHS_HOST}`,
          logId: 'home',
        },
        ...createTopicMenu([topics.default]),
        {
          title: '집들이',
          href: `${OHS_HOST}/projects?writer=self`,
          logId: 'project',
        },
        {
          title: '집사진',
          href: `${OHS_HOST}/contents/card_collections`,
          logId: 'card',
        },
        ...createTopicMenu(topics.fixed),
        {
          title: '3D인테리어',
          href: `${OHS_HOST}/interior3ds`,
          logId: 'interior3d',
        },
        {
          title: '이벤트',
          href: `${OHS_HOST}/competitions/feed`,
          logId: 'event',
        },
      ].filter(Boolean) as MenuSchemaEntry[],
    },
  ]), [topics.default, topics.fixed]);

  useEffect(() => {
    if (experimentType === CONTENTS_EXPERIMENT_TYPE.LEGACY) return;

    const getHnCTopics = async (): Promise<void> => {
      try {
        const res = await getNavigationHnCData();
        const formattedTopics = formatTopics(res.topics);
        setTopics(formattedTopics);
      } catch {
        //
      }
    };

    getHnCTopics();
  }, [experimentType]);

  return experimentType === CONTENTS_EXPERIMENT_TYPE.LEGACY ? legacyMenu : newMenu;
};
