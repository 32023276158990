import { ReactNode, ReactElement, useEffect, useState } from 'react';
// import { useRouter } from 'next/router';

import { useUserContext } from './UserContextProvider';

// import { isInApp } from 'utils';

type BrazeAPI = any;

const BRAZE_API_SRC = 'https://js.appboycdn.com/web-sdk/4.8/braze.min.js';
const BRAZE_TOKEN = '5195e134-c2c9-4bdb-8431-ee5fca29b935';
const BRAZE_BASE_URL = 'sdk.iad-03.appboy.com';
let appboyLoading = null;
let initialized = false;

function getBrazeAPI (): BrazeAPI | null {
  if (typeof window === 'undefined') {
    return null;
  }
  return (window as any).braze;
}

function loadBrazeAPI (): Promise<BrazeAPI> {
  if ((window as any).braze != null) {
    if (!initialized) {
      try {
        (window as any).braze.initialize(BRAZE_TOKEN, {
          baseUrl: BRAZE_BASE_URL,
          allowUserSuppliedJavascript: true,
          enableHtmlInAppMessages: true,
          // debug code
          enableLogging: process.env['APP_PROFILE'] !== 'prod',
        });
        (window as any).braze.display.automaticallyShowNewInAppMessages();
        (window as any).braze.openSession();
        // (window as any).braze.registerBrazePushMessages();
      } catch (e) {
        //
      }
      initialized = true;
    }
    return Promise.resolve((window as any).braze);
  } else if (appboyLoading != null) {
    return appboyLoading;
  } else {
    appboyLoading = new Promise((resolve) => {
      const script = document.createElement('script');
      script.addEventListener('load', () => {
        const Braze = (window as any).braze;
        Braze.initialize(BRAZE_TOKEN, {
          baseUrl: BRAZE_BASE_URL,
          allowUserSuppliedJavascript: true,
          enableHtmlInAppMessages: true,
          // debug code
          enableLogging: process.env['APP_PROFILE'] !== 'prod',
        });
        // Braze.display.automaticallyShowNewInAppMessages();
        Braze.openSession();
        // Braze.registerBrazePushMessages();
        initialized = true;

        resolve(Braze);
      });
      script.src = BRAZE_API_SRC;
      document.body.appendChild(script);
    });
    return appboyLoading;
  }
}

export function useBrazeAPI (
  userId: string,
  onUpdate?: () => void
): BrazeAPI | null {
  const [loaded, setLoaded] = useState(false);
  // const router = useRouter();
  // const query = router.query;
  // const isApp = isInApp(query);
  useEffect(() => {
    if (!loaded) {
      loadBrazeAPI().then(() => {
        setLoaded(true);
        if (onUpdate != null) {
          onUpdate();
        }
      });
    }
  }, [loaded, onUpdate]);

  useEffect(() => {
    if (loaded === true && userId) {
      getBrazeAPI().changeUser(userId);
    }
  }, [userId, loaded]);
  return getBrazeAPI();
}

interface BrazeProviderProps {
  children: ReactNode;
}

export const BrazeProvider = ({
  children,
}: BrazeProviderProps): ReactElement => {
  const user = useUserContext();

  useBrazeAPI(user?.userInfo?.user?.userId?.toString());

  return (
    <div>{ children }</div>
  );
};
