/* eslint-disable */
import React from 'react';
import Script from 'next/script';
import { getLogURL } from '@bucketplace/bucket-log';

import { APP_PROFILE, APP_VERSION } from 'common';

const runPrepareScript = () => {
  window.dataLayer = [];
  window.bucketLog = window.bucketLog || [];
  window.fbq = function () {};
};

const getBucketLogScript = () => (
  <script
    key='logging-js'
    id='logging-js'
    src={getLogURL({ environment: APP_PROFILE, version: APP_VERSION })}
    defer
  />
);

const runProdLogOhouseScript = (user) => {
  window.bucketLog = window.bucketLog || [];
  if (user != null) {
    window.bucketLog.push({
      user_id: user.userId || null,
    });
  }
}

const runGoogleTagManagerScript = (user) => {
  if (user?.userId) {
    var dataLayer = [
      {
        userID: user.userId,
      },
    ];
  }
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(), event: 'gtm.js'
    });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src =
      'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-MK8DN2Q');
};

const getGoogleTagManagerScript = () => {
  <noscript>
    <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MK8DN2Q" height="0" width="0" style="display:none;visibility:hidden" allowfullscreen></iframe>
  </noscript>
}

const runFaceBookPixelCodeScript = () => {
  !function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ?
        n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  }(window,
    document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

  fbq('set', 'autoConfig', 'false', '937204509645248');
  fbq('init', '937204509645248');
  fbq('track', 'PageView');
};

const getFaceBookPixelPrepare = () => (
  <noscript key='facebookpixel-prepare'><img height="1" width="1" style={{display: 'none'}}
  src="https://www.facebook.com/tr?id=937204509645248&ev=PageView&noscript=1"
  /></noscript>
)

const runCriteoScript = (user) => {
  (function () {
    var ua = navigator.userAgent.toLowerCase();
    var mobile = {
      isAndroid: ua.indexOf('android') > -1,
      isIos: ua.match(/(ipad|iphone|ipod)/g),
    };

    var type = '';
    if (mobile.isAndroid || mobile.isIos) {
      type = 'm';
    } else {
      type = 'd';
    }

    window.criteo_q = window.criteo_q || [];

    window.criteo_q.push(
      { event: 'setAccount', account: 50838 },
      {
        event: 'setEmail',
        email: user?.userId ? `${user.userId}@bucketplace.net` : '',
      },
      { event: 'setSiteType', type: type },
    );
  })();
};

const getCriteoScript = () => (
  <script
    key='criteo-js'
    type='text/javascript'
    src='//static.criteo.net/js/ld/ld.js'
    async
  />
);

const runAmplitudeBefore = () => {
  (function(e,t){var n=e.amplitude||{_q:[],_iq:{}};function i(e,t){e.prototype[t]=function(){
  this._q.push([t].concat(Array.prototype.slice.call(arguments,0)));return this}}
  var o=function(){this._q=[];return this};var a=["add","append","clearAll",
  "prepend","set","setOnce","unset","preInsert","postInsert","remove"];for(
  var c=0;c<a.length;c++){i(o,a[c])}n.Identify=o;var l=function(){this._q=[]
  ;return this};var u=["setProductId","setQuantity","setPrice","setRevenueType",
  "setEventProperties"];for(var p=0;p<u.length;p++){i(l,u[p])}n.Revenue=l;var d=[
  "init","logEvent","logRevenue","setUserId","setUserProperties","setOptOut",
  "setVersionName","setDomain","setDeviceId","enableTracking",
  "setGlobalUserProperties","identify","clearUserProperties","setGroup",
  "logRevenueV2","regenerateDeviceId","groupIdentify","onInit","onNewSessionStart"
  ,"logEventWithTimestamp","logEventWithGroups","setSessionId","resetSessionId",
  "getDeviceId","getUserId","setMinTimeBetweenSessionsMillis",
  "setEventUploadThreshold","setUseDynamicConfig","setServerZone","setServerUrl",
  "sendEvents","setLibrary","setTransport"];function v(t){function e(e){
  t[e]=function(){t._q.push([e].concat(Array.prototype.slice.call(arguments,0)))}}
  for(var n=0;n<d.length;n++){e(d[n])}}v(n);n.getInstance=function(e){e=(
  !e||e.length===0?"$default_instance":e).toLowerCase();if(
  !Object.prototype.hasOwnProperty.call(n._iq,e)){n._iq[e]={_q:[]};v(n._iq[e])}
  return n._iq[e]};e.amplitude=n})(window,document);
};

const initAmplitude = (isProduction, user) => {
  window.amplitude
    .getInstance()
    .init(
      isProduction
        ? '5a87b5059b95b7a68fda866acf155c56'
        : 'dc73f13a3e81af36c9f61718642148af',
      user?.userId ?? null,
      function () {
        var accountGroup = '';
        if (user?.isAdmin) {
          accountGroup = '어드민';
        } else if (user?.userableType === 'ExpertUser') {
          accountGroup = '전문가';
        } else if (user?.userableType === 'SalesUser') {
          accountGroup = '판매자';
        } else if (user != null) {
          accountGroup = '일반회원';
        } else {
          accountGroup = null;
        }
        window.amplitude.getInstance().setUserProperties({
          'State of 로그인': user?.userId ? 'true' : 'false',
          'Account Group': accountGroup,
        });
      },
    );
}

const runAmplitudeBridge = (isProduction, user) => {
  const androidAvailable = window.ohouseAmplitudeInterface != null;
  const iosAvailable =
    window.webkit != null &&
    window.webkit.messageHandlers != null &&
    window.webkit.messageHandlers.ohouseAmplitudeInterface != null;
  if (androidAvailable || iosAvailable) {
    // Use a mock interface of Amplitude. However, we shouldn't override
    // original Amplitude object - it contains a queue that needs to be
    // executed.
    // Therefore, instead of overriding the object as whole, we should override
    // its methods.

    // First, read out all objects in window.amplitude
    const amplitude = window.amplitude || { _q: [], _iq: {} };
    window.amplitude = amplitude;

    const override = function (v) {
      // Then, override certain methods we need to track
      v.logEvent = function (name, props, callback) {
        if (androidAvailable) {
          window.ohouseAmplitudeInterface.sendEvent(
            name,
            JSON.stringify(props),
          );
        } else if (iosAvailable) {
          window.webkit.messageHandlers.ohouseAmplitudeInterface.postMessage({
            type: 'sendEvent',
            name,
            properties: props,
          });
        }
        if (callback != null) callback();
      };
      v.setUserProperties = function (props) {
        const identify = new v.Identify();
        // eslint-disable-next-line guard-for-in
        for (const name in props) {
          identify.set(name, props[name]);
        }
        v.identify(identify);
      };
      v.identify = function (identify) {
        // Fortunately identify's _q is equivalent to what we need to send
        if (androidAvailable) {
          window.ohouseAmplitudeInterface.identify(JSON.stringify(identify._q));
        } else if (iosAvailable) {
          window.webkit.messageHandlers.ohouseAmplitudeInterface.postMessage({
            type: 'identify',
            actions: identify._q,
          });
        }
      };
      // Supply 'Identify', 'Revenue' object
      v.Identify = amplitude.Identify;
      v.Revenue = amplitude.Revenue;
      // Finally, execute the queue.
      for (let i = 0; i < v._q.length; i += 1) {
        const command = v._q[i];
        if (
          ['logEvent', 'setUserProperties', 'identify'].indexOf(command[0]) !==
          -1
        ) {
          // eslint-disable-next-line prefer-spread
          v[command[0]].apply(v, command.slice(1));
        }
      }
      v._q = [];
    };

    // Override amplitude object itself, and _iq
    override(amplitude);

    // eslint-disable-next-line guard-for-in
    for (const name in amplitude._iq) {
      override(amplitude._iq[name]);
    }
  } else {
    // Normal environment detected; just use regular SDK
    let r = document.createElement('script');
    r.type = 'text/javascript';
    r.integrity = 'sha384-cukXwabQy+j/QA1+RqiXSzxhgQg5Rrn3zVszlwH3pWj/bXJxlA8Ge7NhcD6vP2Ik';
    r.crossOrigin = 'anonymous';
    r.async = true;
    r.src = 'https://cdn.amplitude.com/libs/amplitude-7.1.0-min.gz.js';
    r.onload = function () {
      if (!window.amplitude.runQueuedFunctions) {
        console.log('[Amplitude] Error: could not load SDK');
      }
      initAmplitude(isProduction, user);
    };
    let i = document.getElementsByTagName('script')[0];
    i.parentNode.insertBefore(r, i);
  }
}

export const runAmplitudeScript = async (isProduction, user) => {
  await runAmplitudeBefore();
  await runAmplitudeBridge(isProduction, user);
};

const getADInsightScript = () => (
  <>
    <script type="text/javascript">
      var TRS_AIDX = /iPhone|iPad|iPod|Android/.test(navigator.userAgent) ? 11603 : 11598;
    </script>
    <script key='ad-insight-js' type="text/javascript" src="https://analysis.adinsight.co.kr/emnet/trs_esc.js" defer />
  </>
);

const runSentryTestingScript = (isProduction, user) => {
  if (isProduction) {
    Raven.config('https://b8e5cc27b3eb44be8b976b0c81dd4115@sentry.io/271486').install();
    if (user != null) {
      Raven.setUserContext({
        id: user.userId,
        email: user.userId ? `${user.userId}@bucketplace.net` : '',
        nickname: user.nickname
      });
    }
    Raven.setUserContext({});
  }
}

const getSentryTestingScript = () => (
  <script key='sentry-testing-js' src="https://cdn.ravenjs.com/3.26.4/raven.min.js" crossOrigin="anonymous" />
);

const getJSONDataScript = () => {
  const structuredData = {
    "@context": "http://schema.org",
    "@type": "Organization",
    "name": "오늘의집",
    "url": "https://ohou.se",
    "sameAs": [
      "https://www.instagram.com/todayhouse/?hl=ko",
      "https://www.facebook.com/interiortoday",
      "https://m.post.naver.com/my.nhn?memberNo=202156",
      "https://itunes.apple.com/kr/app/id1008236892",
      "https://play.google.com/store/apps/details?id=net.bucketplace&hl=ko",
      "https://www.youtube.com/channel/UCBKtitA1RwY7F32rCniV1dA/featured"
    ]
  };
  return (
    <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
    />
  );
}

const runAirbridgeScript = (user) => {
  (function (a_, i_, r_, _b, _r, _i, _d, _g, _e) { if (!a_[_b]) { var d = { queue: [] }; _r.concat(_i).forEach(function (a) { var i_ = a.split("."), a_ = i_.pop(); i_.reduce(function (a, i_) { return a[i_] = a[i_] || {} }, d)[a_] = function () { d.queue.push([a, arguments]) } }); a_[_b] = d; a_ = i_.getElementsByTagName(r_)[0]; i_ = i_.createElement(r_); i_.onerror = function () { d.queue.filter(function (a) { return 0 <= _i.indexOf(a[0]) }).forEach(function (a) { a = a[1]; a = a[a.length - 1]; "function" === typeof a && a("error occur when load airbridge") }) }; i_.async = 1; i_.src = "//static.airbridge.io/sdk/latest/airbridge.min.js"; a_.parentNode.insertBefore(i_, a_) } })(window, document, "script", "airbridge", "init fetchResource setBanner setDownload setDownloads setDeeplinks sendWeb setUserAgent setUserAlias addUserAlias setMobileAppData setUserId setUserEmail setUserPhone setUserAttributes clearUser setDeviceIFV setDeviceIFA setDeviceGAID events.send events.signIn events.signUp events.signOut events.purchased events.addedToCart events.productDetailsViewEvent events.homeViewEvent events.productListViewEvent events.searchResultViewEvent".split(" "), ["events.wait"]);

  const initObject = {
    app: 'ohouse',
    webToken: '2bd43efc5ef8401baece946c84ba155d',
    cookieWindow: 1,
    userHash: false,
    urlQueryMapping: {
      channel: 'utm_source',
      campaign: 'utm_campaign',
      term: 'utm_term',
      ad_creative: 'utm_content',
      ad_group: 'bp_adset',
      content: 'bp_content',
      sub_id: 'bp_sub_id',
      sub_id_1: 'bp_sub_id_1',
      sub_id_2: 'bp_sub_id_2',
      sub_id_3: 'utm_medium',
    },
  };

  if (user != null) {
    initObject.user = {
      externalUserID: `${user.userId}`,
      externalUserEmail: `${user.userId}@bucketplace.net`,
    };
  }
  airbridge.init(initObject);
};

const runA1mediaScript = () => {
  var linkback = linkback || {};
  (function() {
    var d = document, scr = d.createElement('script'),
    tar = d.getElementsByTagName('script')[0];
    scr.type = 'text/javascript';
    scr.async = true;
    var dt = new Date();
    var date_s = dt.getFullYear().toString()+('0' + (dt.getMonth()+1)).slice(-2)+('0' + dt.getDate()).slice(-2);
    scr.src = 'https://linkback.contentsfeed.com/src/' + date_s + '/lb4ohouse.min.js';
    scr.charset='utf-8';
    if(!linkback.l){linkback.l=true; tar.parentNode.insertBefore(scr, tar);}
  })();
};

export const getMarketingHeaderScript = (userInfo) => {
  const isProduction = process.env.NODE_ENV === 'production';

  const scriptList = [];
  if (userInfo) {
    scriptList.push(getBucketLogScript());
  }
  if (isProduction) {
    scriptList.push(getFaceBookPixelPrepare());
    scriptList.push(getCriteoScript());
    scriptList.push(getADInsightScript());
    scriptList.push(getSentryTestingScript());
    scriptList.push(getJSONDataScript());
    scriptList.push(getGoogleTagManagerScript());
  }

  return scriptList;
}

export const runMarketingHeaderScript = (user) => {
  const isProduction = process.env.NODE_ENV === 'production';

  if (isProduction) {
    runGoogleTagManagerScript(user);
    runFaceBookPixelCodeScript();
    runCriteoScript(user);
    runProdLogOhouseScript(user);
    runAmplitudeScript(isProduction, user);
    runSentryTestingScript(isProduction, user);
    runAirbridgeScript(user);
    runA1mediaScript();
  } else {
    runPrepareScript();
  }
};
