import React, { createContext, useContext, useEffect, useState } from 'react';
import router from 'next/router';
import { camelizeKeys } from 'humps';

import { OSType, SearchQueryType, searchQueryKeyList, SearchQueryKeyType } from 'types';
import { getOSType, filter, getQuery } from 'utils';

const defaultQuery = {
  osType: 'WEB' as OSType,
  qaMode: false,
};

const SearchQueryContext = createContext<SearchQueryType>({});

export const SearchQueryContextProvider: React.FC =
({ children }): JSX.Element => {
  const [query, setQuery] = useState<SearchQueryType>(defaultQuery);

  useEffect(() => {
    handleQuery();
    router.events.on('routeChangeComplete', handleQuery);
  }, []);

  const handleQuery = (): void => {
    const query: SearchQueryType = camelizeKeys(getQuery(location.href));
    const queryList = filter(query, (_, k: SearchQueryKeyType) => {
      return searchQueryKeyList.includes(k);
    });

    const idQuery = (router.query.id as string)?.replace('%20', '').trim();

    setQuery({
      ...defaultQuery,
      ...queryList,
      id: idQuery,
      osType: getOSType(router.query),
    });
  };

  return (
    <SearchQueryContext.Provider value={query}>
      { children }
    </SearchQueryContext.Provider>
  );
};

export const useSearchQueryContext = (): SearchQueryType => {
  const context = useContext(SearchQueryContext);
  return context;
};
