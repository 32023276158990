import { useEffect, useState } from 'react';

import { useScrollContext, useTabContext } from '../provider';

export const useScrollSpy = (
  id: string,
  isAnchoring: boolean,
  isFirstTab: boolean,
  isMainTab: boolean
): {
  isActiveAnchor: boolean,
} => {
  const [target, setTarget] = useState<HTMLElement>(null);
  const [isActive, setIsActive] = useState<boolean>(false);
  const { stickyHeight, scrollPosition } = useScrollContext();
  const { currentMainId } = useTabContext();

  const top = target?.getBoundingClientRect()?.top;
  const offsetTop = scrollPosition + top || 0;

  const startPosition = Math.floor(offsetTop - stickyHeight);
  const endPosition = Math.floor(
    startPosition +
    target?.getBoundingClientRect()?.height
  );
  const isInScreen =
    startPosition <= scrollPosition &&
    scrollPosition < endPosition;
  const isBeforeReachingTarget = scrollPosition < startPosition;

  useEffect(() => {
    setTarget(document.getElementById(id));
  }, [id]);

  useEffect(() => {
    setIsActive(isInScreen);

    if (isFirstTab && isBeforeReachingTarget) {
      setIsActive(true);
    }
  }, [scrollPosition, isBeforeReachingTarget, isFirstTab, isInScreen]);

  useEffect(()=> {
    if (!isMainTab && isFirstTab) {
      setIsActive(true);
    }
  }, [currentMainId, isFirstTab, isMainTab]);

  return { isActiveAnchor: isAnchoring && isActive };
};
