export const ANDROID_KAKAO_SHARE_VERSION = '9.64.1';
export const REDIRECT_PATH = '/promotions/festa-sale';

export const ONE_SECOND = 1000;
export const ONE_MINUTE = 60 * ONE_SECOND;
export const ONE_HOUR = 60 * ONE_MINUTE;
export const ONE_DAY = 24 * ONE_HOUR;

export const ZINDEX = {
  TAB: 100,
  MODAL: 1300,
};
