import { MutableRefObject } from 'react';
import { BucketLogData } from '@bucketplace/bucket-log';

export interface DeliveryStartAt {
  template: {
    text: string,
    values: string[],
  };
}

export const DELIVERY_SERVICE_CODE = {
  /** 화물택배 배송 */
  Cargo: 'CARGO',
  /** 미노출 */
  None: 'NONE',
  /** 일반택배 */
  Normal: 'NORMAL',
  /** 오늘의집 배송 */
  Ohouse: 'OHOUSE',
  /** 직접배송 */
  Seller: 'SELLER',
  /** 빠른가전배송 */
  ThirdPartyAppliance: 'THIRD_PARTY_APPLIANCE',
  /** 빠른가구배송 */
  ThirdPartyFurniture: 'THIRD_PARTY_FURNITURE',
} as const;
export type DeliveryServiceCode = typeof DELIVERY_SERVICE_CODE[keyof typeof DELIVERY_SERVICE_CODE];

export interface Badge {
  id: number;
  thumbnailBadgeId: number;
  position: number;
  image: {
    url: string,
    width?: number,
    height?: number,
  };
}

export interface BenefitBadge {
  ozipTitle: string;
  image?: string;
  title?: string;
}

export interface Product {
  brand: {
    id: number,
    name: string,
  };
  brandId: number;
  brandName: string;
  cost: number;
  deliveryType: number;
  freeDelivery: boolean;
  id: number;
  imageUrl: string;
  isBespoke: boolean;
  isBuyable: boolean;
  isCheapestPrice: boolean;
  isConsultable: boolean;
  isDeliveryDateSpecified: boolean;
  isDiscontinued: boolean;
  isDiscounted: boolean;
  isFreeDelivery: boolean;
  isHidden: boolean;
  isOverseasPurchase: boolean;
  isRemodel: boolean;
  isRetailDelivery: boolean;
  isThirdPartyLogistic: boolean;
  isThirdPartyFurniture: boolean;
  isDepartureToday: boolean;
  deliveryStartAt?: DeliveryStartAt;
  deliveryServiceCode?: DeliveryServiceCode;
  isScrap: boolean;
  isSelling: boolean;
  isSoldOut: boolean;
  isSpecialPrice: boolean;
  isWish: boolean;
  lowestGuarantee: boolean;
  name: string;
  originalImageUrl: string;
  originalPrice: number;
  rawImageUrl: string;
  resizedImageUrl: string;
  reviewAvg: number;
  reviewCount: number;
  scrapCount: number;
  selling: boolean;
  sellingCost: number;
  sellingPrice: number;
  soldOut: boolean;
  status: number;
  type: string;
  usedCardCount: number;
  userId: number;
  viewCount: number;
  weekRank: number;
  wishCount: number;
  badges: Badge[];
  benefitBadges?: BenefitBadge[];
  log?: BucketLogData;
  endAt?: string;
  soldCount?: number;
  isBadgeHide?: boolean;
  title?: string;
}

export interface ProductGroupData {
  id: number;
  name: string;
  path?: string;
  products: Product[];
  categoryRef? : MutableRefObject<HTMLDivElement>;
  grid?: number;
  isScroll?: boolean;
}

export interface Production {
  type: string;
  id: number;
  name: string;
  reviewAvg: number;
  reviewCount: number;
  scrapCount?: number;
  sellingPrice: number;
  originalPrice: number;
  originalImageUrl: string;
  brand: {
    id: number,
    name: string,
  };
  badges?: Badge[];
  isSpecialPrice: boolean;
  isSelling: boolean;
  isSoldOut: boolean;
  isScrap: boolean;
  isFreeDelivery: boolean;
  isDiscontinued: boolean;
  isDiscounted: boolean;
  isOverseasPurchase: boolean;
  isRetailDelivery: boolean;
  isThirdPartyLogistic: boolean;
  isDepartureToday?: boolean;
  deliveryStartAt: DeliveryStartAt;
  deliveryServiceCode: DeliveryServiceCode;
  benefitBadges?: BenefitBadge[];
}
