import React, { createContext, ReactNode, useContext } from 'react';

import { EventInfoType } from 'campaign/types';

interface RaffleInfoType extends EventInfoType {
  pageEnterDate: Date;
}

const RaffleInfoContext = createContext<RaffleInfoType | null>(null);

export const RaffleInfoProvider = ({
  children,
  initialData,
}: {
  children: ReactNode,
  initialData: RaffleInfoType,
}): React.ReactElement => {
  return (
    <RaffleInfoContext.Provider value={initialData}>
      { children }
    </RaffleInfoContext.Provider>
  );
};

export const useRaffleInfoContext = (): RaffleInfoType | null => {
  const context = useContext(RaffleInfoContext);
  return context;
};
