/* eslint-disable @typescript-eslint/no-empty-function */
import React, { PropsWithChildren, ReactElement, useCallback, useState, useContext } from 'react';

import { ConfirmModal, ConfirmModalProps } from '../components/ConfirmModal';

type OpenConfirmModalParam = Pick<ConfirmModalProps, 'confirmText'| 'secondaryText' | 'message'>;
type OpenAlertModalParam = OpenConfirmModalParam;

export interface GlobalModalContextType {
  openConfirmModal: (param: OpenConfirmModalParam) => Promise<boolean>;
  openAlertModal: (param: OpenAlertModalParam) => Promise<void>;
}

export const GlobalModalContext = React.createContext<GlobalModalContextType>({
  openConfirmModal: () => new Promise(()=> {}),
  openAlertModal: () => new Promise(() => {}),
});

/**
 * 다양한 곳에서 공통으로 사용되는 Confirm, Alert 모달을 생성하는 메서드를 제공합니다.
 * openConfirmModal: 공통으로 사용되는 Confirm 모달을 생성합니다.
 * openAlertModal: 공통으로 사용되는 Alert 모달을 생성합니다.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function GlobalModalProvider({ children }: PropsWithChildren<{}>): ReactElement {
  const [confirmModalProps, setConfirmModalProps] = useState<ConfirmModalProps | null>(null);

  const openConfirmModal = useCallback((props: OpenConfirmModalParam): Promise<boolean> => {
    return new Promise((resolve) => {
      setConfirmModalProps({
        ...props,
        cancelable: true,
        onConfirm: () => {
          setConfirmModalProps(null);
          resolve(true);
        },
        onCancel: () => {
          setConfirmModalProps(null);
          resolve(false);
        },
      });
    });
  }, []);

  const openAlertModal = useCallback((props: OpenAlertModalParam): Promise<void> => {
    return new Promise((resolve) => {
      setConfirmModalProps({
        ...props,
        cancelable: false,
        onConfirm: () => {
          resolve();
          setConfirmModalProps(null);
        },
      });
    });
  }, []);


  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <GlobalModalContext.Provider value={{ openConfirmModal, openAlertModal }}>
      { children }
      { confirmModalProps && (
        <ConfirmModal {...confirmModalProps} />
      ) }
    </GlobalModalContext.Provider>
  );
}

export function useGlobalModal(): GlobalModalContextType {
  return useContext(GlobalModalContext);
}
