import { createLogger, format, transports } from 'winston';

// https://docs.datadoghq.com/logs/log_collection/nodejs/?tab=winston30
export const logger = createLogger({
  level: 'info',
  exitOnError: false,
  format: format.json(),
  transports: [
    new transports.Console({
      format: format.json(),
    }),
  ],
});

export default logger;
