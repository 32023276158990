import { camelizeKeys } from 'humps';

import { clientHttp } from 'common/http';

import { NavigationHnCData } from 'types';

export const getNavigationData = async (): Promise<any> => {
  const response = await clientHttp.get('/api/navigation', { throwHttpErrors: false });
  if (!response.ok) {
    throw new Error('unexpected http error occurred');
  }
  const json = await response.json();
  return camelizeKeys(json);
};

export const getNavigationHnCData = async (): Promise<NavigationHnCData> => {
  const response = await clientHttp.get('/api/navigation/topics');
  const data = await response.json<any>();
  return data;
};
