import { decamelizeKeys } from 'humps';

export function sendBrazeLog (
  eventName: string,
  log: { [key: string]: any }
): void {
  if ((window as any).braze?.logCustomEvent != null) {
    (window as any).braze = (window as any).braze || {};
    (window as any).braze.logCustomEvent(eventName, decamelizeKeys(log));
  }
}

export const sendBrazeAppLog = (name: string, properties: unknown): void => {
  if ((window as any).webkit?.messageHandlers?.ohouseBrazeInterface != null) {
    (window as any).webkit.messageHandlers.ohouseBrazeInterface.postMessage(
      { name, properties }
    );
  } else if ((window as any)?.ohouseBrazeInterface != null) {
    (window as any)
      .ohouseBrazeInterface.sendLog(name, JSON.stringify(properties));
  }
};
