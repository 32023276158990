import { createContext, ReactElement, ReactNode, useContext } from 'react';
import useSWR from 'swr';

import { PushSetting, PushSettingResponse } from 'campaign/types';

const PushSettingContext = createContext<PushSetting>(null);

export const PushSettingProvider = ({
  children,
}: {
  children: ReactNode,
}): ReactElement => {
  const { data } = useSWR<PushSettingResponse>('/api/campaign/raffle/push-setting');

  return (
    <PushSettingContext.Provider value={data?.data}>
      { children }
    </PushSettingContext.Provider>
  );
};

export const usePushSettingContext = (): PushSetting | null => {
  return useContext(PushSettingContext);
};
