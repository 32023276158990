import React from 'react';

import { useNowTime } from '../hooks';

type ReturnFunction =
  (original: React.ReactElement | null, change: React.ReactElement | null) => React.ReactElement | null;

export const useAutoChangeComponent = (targetDate : Date) : ReturnFunction => {
  const { data: nowDate, error } = useNowTime();

  if (!nowDate || error) {
    return (original, _) => original;
  }

  return (original, change) => {
    if (nowDate > targetDate) {
      return change;
    } else {
      return original;
    }
  };
};
