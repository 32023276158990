import { useLayoutEffect } from 'react';

import { bucketLog } from '@bucketplace/bucket-log';
/**
 * This prevents automated pageview log from occurring.
 * With this hook, you can issue pageview event with additional data,
 * or prevent pageview event from occurring at all.
 */
export function usePreventAutoPageviewLog (): void {
  useLayoutEffect(() => {
    bucketLog({
      category: 'disableAutoPageview',
    });
    return () => {
      bucketLog({
        category: 'enableAutoPageview',
      });
    };
  }, []);
}
