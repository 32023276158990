import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ScrollProvider } from '../../provider';
import { getAppOsInfo } from '../../utils';
import { HeaderContainer } from './HeaderContainer';
import { FooterContainer } from './FooterContainer';

interface PageLayoutProps{
  children: JSX.Element;
}

export const PageLayout = ({
  children,
} : PageLayoutProps): React.ReactElement => {
  const [footerHeight, setFooterHeight] = useState<number>(0);
  const footerRef = useRef<HTMLDivElement>(null);
  const isAndroid = getAppOsInfo()?.osType === 'Android';

  useEffect(()=> {
    const handleFooterHeight = (): void => setFooterHeight(footerRef.current.offsetHeight);

    if (footerRef.current) {
      setFooterHeight(footerRef.current.offsetHeight);
      window.addEventListener('resize', handleFooterHeight);
    }

    return () => {
      window.removeEventListener('resize', handleFooterHeight);
    };
  }, [footerRef]);

  return (
    <ContainerColumn>
      <ScrollProvider>
        <HeaderContainer />
        <ContainerRow footerHeight={footerHeight}>
          <ContentsContainer>
            <PageWrap>
              { children }
            </PageWrap>
          </ContentsContainer>
        </ContainerRow>
        <FooterContainer footerRef={footerRef} />
      </ScrollProvider>
      <ToastContainer
        position="bottom-center"
        hideProgressBar
        autoClose={2000}
        transition={Slide}
        style={{
          marginBottom: isAndroid ? '42px' : '0px',
          zIndex: 10001,
        }}
      />
    </ContainerColumn>
  );
};

const ContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
`;

const ContainerRow = styled.div<{ footerHeight: number }>`
  display: flex;
  width: 100%;
  padding-bottom: ${({ footerHeight }) => footerHeight}px;
`;

const ContentsContainer= styled.div`
  width: 100%;
  @media screen and (min-width: 768px) {
    padding: 0px 60px;
    margin: 0 auto;
    box-sizing: border-box;
    flex: 1 0 auto;
    max-width: 1256px;
  }
`;

const PageWrap =styled.div`
  width: 100%;
`;
