import { MutableRefObject, useEffect, useState } from 'react';

export const useIsVideoPlaying = (
  videoRef: MutableRefObject<HTMLVideoElement>
): boolean => {
  const [isVideoPlaying, setVideoPlaying]
    = useState<boolean>(false);

  useEffect(() => {
    if (!videoRef.current) return null;
    const videoDOM = videoRef.current;

    const handleSuspend = (): void => {
      setVideoPlaying(false);
    };
    const handlePlay = () : void => {
      setVideoPlaying(true);
    };

    videoDOM.addEventListener('suspend', handleSuspend);
    videoDOM.addEventListener('pause', handleSuspend);
    videoDOM.addEventListener('play', handlePlay);

    return () => {
      videoDOM.removeEventListener('suspend', handleSuspend);
      videoDOM.removeEventListener('pause', handleSuspend);
      videoDOM.removeEventListener('play', handlePlay);
    };
  }, [videoRef]);

  return isVideoPlaying;
};
