import 'regenerator-runtime/runtime';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';

import React, { useEffect, ReactNode } from 'react';
import { SWRConfig } from 'swr';
import { NextPage } from 'next';
import App, { AppProps } from 'next/app';
import { useRouter } from 'next/router';

import { init } from '@bucketplace/metadata/next';
import { DesignSystemProvider } from '@bucketplace/design-system';
import { initDatadogRum } from '@bucketplace/common';

import { APP_PROFILE, APP_NAME, APP_VERSION } from 'common';
import { sessionManager } from 'common/jwt-auth';
import {
  UserContextProvider,
  SearchQueryContextProvider,
  GlobalModalProvider,
  PushSettingProvider,
  BrazeProvider,
} from 'provider';
import { fetcher } from 'api/fetcher';
import { setUtmData, saveAppOsInfo, sendA1MediaInflow } from 'utils';

import { Layout, PageLayout } from 'components/Layout';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { Error } from 'components/Error';

import 'styles/global.css';

init.browser({ environment: APP_PROFILE, name: APP_NAME, version: APP_VERSION ?? APP_PROFILE });
sessionManager.prepare();
initDatadogRum({
  applicationId: '8c6ee7a4-2a88-4279-8468-5b3710dd460f',
  clientToken: 'pub7bcc0f23149f0ea6696b2d6f604c6919',
  service: 'event-web',
  env: APP_PROFILE === 'local' ? 'dev' : APP_PROFILE,
  version: APP_VERSION,
  sessionSampleRate: 1,
  sessionReplaySampleRate: 0,
});


type Page<P = Record<string, never>> = NextPage<P> & {
  getLayout?: (page:ReactNode) => ReactNode,
};

type MyAppProps = AppProps & {
  Component: Page,
};

function MyApp ({ Component, pageProps } : MyAppProps): React.ReactElement {
  const router = useRouter();

  useEffect(() => {
    setUtmData();
    saveAppOsInfo();
    sendA1MediaInflow();

    router.events.on('routeChangeComplete', setUtmData);
    return () => router.events.off('routeChangeComplete', setUtmData);
  }, [router.events]);

  const getLayout = Component.getLayout || ((page:ReactNode) => page);
  return (
    <DesignSystemProvider mode="light">
      <SWRConfig
        value={{
          revalidateOnFocus: false,
          fetcher,
        }}
      >
        <SearchQueryContextProvider>
          <UserContextProvider>
            <GlobalModalProvider>
              <PushSettingProvider>
                <BrazeProvider>
                  <Layout>
                    <PageLayout>
                      <ErrorBoundary fallback={<Error />}>
                        { getLayout(<Component {...pageProps} />) }
                      </ErrorBoundary>
                    </PageLayout>
                  </Layout>
                </BrazeProvider>
              </PushSettingProvider>
            </GlobalModalProvider>
          </UserContextProvider>
        </SearchQueryContextProvider>
      </SWRConfig>
    </DesignSystemProvider>
  );
}

MyApp.getInitialProps = async (context) => {
  const appProps = await App.getInitialProps(context);
  return { ...appProps };
};

export default MyApp;
