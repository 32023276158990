import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { useScrollSpy } from './index';
import { useTabContext } from '../provider';

export const useTab = (
  to: string,
  isFirstTab: boolean,
  isMainTab: boolean,
  isAnchoring = true,
  boundaryPrefix?: string
): {
  isActive: boolean,
  href: string,
} => {
  const router = useRouter();
  const [isActive, setIsActive] = useState<boolean>(false);
  const {
    isActiveAnchor,
  } = useScrollSpy(boundaryPrefix ? `${boundaryPrefix}-${to}` : to, isAnchoring, isFirstTab, isMainTab);

  const { setCurrentMainId } = useTabContext();

  const href = isAnchoring ? `#${to}` : `/${to}`;

  useEffect(() => {
    if (isAnchoring) return;

    if (href.includes(router.pathname)) {
      setIsActive(true);
    }
  }, [router.pathname, href, isAnchoring]);

  useEffect(() => {
    if (!isAnchoring) return;
    if (isMainTab && isActiveAnchor) {
      setCurrentMainId(to);
    }

    setIsActive(isActiveAnchor);
  }, [isActiveAnchor, isMainTab, setCurrentMainId, to, isAnchoring]);

  return { isActive, href };
};
