import { Icon, MenuSchemaEntry } from '@bucketplace/design-system/bds';

import { O2O_HOST, OHS_HOST } from 'common';

export const createO2OMenu = (): MenuSchemaEntry[] => {
  return [
    {
      title: '이사/시공/수리',
      icon: <Icon size={24} name="truck_outline" />,
      href: `${OHS_HOST}/experts`,
      entries: [
        {
          title: '홈',
          href: `${OHS_HOST}/experts`,
        },
        {
          title: '주거공간시공',
          href: `${O2O_HOST}/remodeling/home/discovery`,
        },
        {
          title: '상업공간시공',
          href: `${O2O_HOST}/remodeling/business/discovery`,
        },
        {
          title: '아파트시공사례',
          href: `${OHS_HOST}/experts/town`,
        },
        {
          title: '설치수리',
          href: `${O2O_HOST}/repair`,
        },
        {
          title: '이사',
          href: `${OHS_HOST}/experts/moving`,
        },
        {
          title: '집보기체크리스트',
          href: `${O2O_HOST}/hometour`,
        },
      ],
    },
  ];
};
