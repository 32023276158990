import React from 'react';
import { useRouter } from 'next/router';

import { useExperimentType } from '@bucketplace/xpc-sdk';

import { useNavigationMenu } from '../../hooks/Navigation';
import { Header } from './Header';

export const CONTENTS_EXPERIMENT_KEY = 641;
export const CONTENTS_EXPERIMENT_TYPE = {
  LEGACY: 'A',
  NEW: 'B',
};

export const HeaderContainer = (): React.ReactElement | null => {
  const router = useRouter();

  const { experimentType: contentsRenewalExperimentType = CONTENTS_EXPERIMENT_TYPE.LEGACY } = useExperimentType({
    experimentKey: CONTENTS_EXPERIMENT_KEY,
    type: 'AB',
  });

  const experiments = {
    contents: contentsRenewalExperimentType,
  };

  const {
    data: navigationMenu,
    error,
  } = useNavigationMenu(router.pathname, experiments);

  if (error || !navigationMenu) return null;

  return (
    <Header />
  );
};
