import { useEffect, useState } from 'react';

/**
 * 디바이스 화면 비율 height / width
 */
export const SWITCHED_DEVICE_RATIO = 812 / 375;

export type RatioType = 'long' | 'short';
/**
 * 디바이스 비율 종류를 반환합니다.
 * @return {string} 'long' | 'short'
 */
export const useDeviceRatioType = (): RatioType => {
  const [ratio, setRatio] = useState<RatioType>('long');

  useEffect(() => {
    const ratio = window.screen.availHeight / window.screen.availWidth;
    setRatio(() => ratio >= SWITCHED_DEVICE_RATIO ? 'long' : 'short');
  }, []);

  if (typeof window === 'undefined') return 'long';

  return ratio;
};
