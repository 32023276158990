import { ANDROID_KAKAO_SHARE_VERSION } from 'common';
import { getAppOsInfo } from '../../utils/OSVersion';

declare let Kakao: any;
type KakaoAPI = any;

const KAKAO_API_SRC = '//developers.kakao.com/sdk/js/kakao.min.js';
const KAKAO_AUTH_TOKEN = process.env.NEXT_PUBLIC_KAKAO_API_KEY;
export const ANDROID_VERSION_ERROR_NAME = 'Android Version Error';

interface ShareKakaoType {
  templateId: number;
  templateArgs?: Record<string, string>;
  onFail?: (error: Error) => void;
}

const isKakaoShareVersionOkay = (current: string): boolean => {
  const versionArr = current.split('.');
  const targetVersionArr = ANDROID_KAKAO_SHARE_VERSION.split('.');

  if (versionArr.length !== targetVersionArr.length) return false;

  for (let i=0; i<versionArr.length; i++) {
    const version = Number(versionArr[i]);
    const targetVersion = Number(targetVersionArr[i]);

    if (isNaN(version) || isNaN(targetVersion)) return false;
    if (version > targetVersion) return true;
  }

  return current === ANDROID_KAKAO_SHARE_VERSION;
};

export const shareKakao = ({
  templateId,
  templateArgs,
  onFail,
}: ShareKakaoType): void => {
  fetchKakaoAPI().then(() => {
    const osInfo = getAppOsInfo();
    const isAndroidOldVersion =
      (osInfo.osType === 'Android') &&
      !isKakaoShareVersionOkay(osInfo.osVersion);

    if (isAndroidOldVersion) {
      const versionError = new Error('Android version Error');
      versionError.name = ANDROID_VERSION_ERROR_NAME;

      throw versionError;
    }
    Kakao.Share.sendCustom({
      templateId,
      templateArgs,
    });
  }).catch((error) => {
    onFail(error);
  });
};


let kakaoAPILoading = null;
let initialized = false;

export const fetchKakaoAPI = (): Promise<KakaoAPI> => {
  if ((window as any).Kakao != null) {
    if (!initialized) {
      try {
        Kakao.init(KAKAO_AUTH_TOKEN);
      } catch (e) {
        //
      }
      initialized = true;
    }
    return Promise.resolve((window as any).Kakao);
  } else if (kakaoAPILoading != null) {
    return kakaoAPILoading;
  }
  kakaoAPILoading = new Promise((resolve) => {
    // Load the script from daum...
    const script = document.createElement('script');
    script.addEventListener('load', () => {
      const { Kakao } = window as any;
      Kakao.init(KAKAO_AUTH_TOKEN);
      initialized = true;
      resolve(Kakao);
    });
    script.src = KAKAO_API_SRC;
    document.body.appendChild(script);
  });
  return kakaoAPILoading;
};
