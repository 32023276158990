const VISITOR_ID_KEY = 'b_visitor_id';

export const getCookie = (cookieName: string): string => {
  let cookieValue = null;
  if (document?.cookie) {
    const array = document.cookie.split(`${escape(cookieName)}=`);
    if (array.length >= 2) {
      const arraySub = array[1].split(';');
      cookieValue = unescape(arraySub[0]);
    }
  }
  return cookieValue;
};

export const setCookie = (name, value, delay): void => {
  document.cookie = [
    `${name}=${value}`,
    'path=/',
    'domain=.ohou.se',
    `expires=${new Date(Date.now() + delay).toUTCString()}`,
  ].join(';');
  document.cookie = [
    `${name}=${value}`,
    'path=/',
    `expires=${new Date(Date.now() + delay).toUTCString()}`,
  ].join(';');
};

/**
 * document.cookie를 object 형태로 변환합니다.
 * @param {string} cookieString document.cookie
 * @return {object} cookie의 키-값 쌍
 */
export const getDocumentCookieObject = (cookieString: string): Record<string, string> => {
  return cookieString.split('; ').reduce((prev, current) => {
    const [name, ...value] = current.split('=');
    prev[name] = value.join('=');
    return prev;
  }, {});
};

/**
 * document.cookie에서 b_visitor_id 값을 추출합니다.
 * @param {object} context next api context
 * @return {string|undefined} b_visitor_id 값
 */
export const getVisitorId = (context): string => {
  const cookieObj = getDocumentCookieObject(context.req?.headers?.cookie || '');
  return cookieObj[VISITOR_ID_KEY];
};
