import React from 'react';
import Link from 'next/link';

interface SuperLinkProps extends Omit<React.HTMLProps<HTMLAnchorElement>, 'href'> {
  href: string;
  useAnchor?: boolean;
  children: React.ReactNode;
}

export const SuperLink = ({
  href,
  useAnchor,
  children,
  ...props
}: SuperLinkProps): React.ReactElement => {
  if (useAnchor) {
    return (
      <a href={href} {...props}>
        { children }
      </a>
    );
  }

  return (
    <Link href={href || ''} passHref>
      <a href={href} {...props}>
        { children }
      </a>
    </Link>
  );
};
