export const throttle = (callback: (args: any) => void, limit = 100): (args: any) => void => {
  let waiting = false;
  return (args) => {
    if (!waiting) {
      callback(args);
      waiting = true;
      setTimeout(() => {
        waiting = false;
      }, limit);
    }
  };
};
