/* eslint-disable */
import React from 'react';
import Script from 'next/script';

const getNaverSearch = () => (
  <Script key='naver-search-js' type="text/javascript" src="https://wcs.naver.net/wcslog.js" />
);

const runNaverSearch = () => {
  if (window.wcs != null) {
    if (!window.wcs_add) window.wcs_add = {};
    window.wcs_add['wa'] = 's_2e85116be219';
    if (!_nasa) var _nasa = {};
    wcs.inflow('ohou.se');
    wcs_do(_nasa);
  }
};

const getKakaoMomentScript = () => (
  <Script key='kakao-moment-js' type="text/javascript" charset="UTF-8" src="//t1.daumcdn.net/adfit/static/kp.js"></Script>
);

const runKakaoMomentScript = () => {
  if (window.kakaoPixel != null) {
    kakaoPixel('7919701595446099671').pageView();
  }
};

const getEnlipleScript = () => (
  <Script key='enliple-js' src="https://cdn.megadata.co.kr/js/en_script/3.5/enliple_min3.5.js" async="async" onLoad={function(){
    var rf = new EN();
    rf.setData('userid', 'bucketplace');
    rf.setSSL(true);
    rf.sendRf();
    if (typeof handleEnlipleLoadShop === 'function') {
      handleEnlipleLoadShop();
    }
  }}/>
)

const getWiderPlanetScript = () => (
  <Script key='wider-planet-js' type="text/javascript" async="async" src="//cdn-aitg.widerplanet.com/js/wp_astg_4.0.js"></Script>
);

const getWiderPrepareDiv = () => (
  <div key='wider-planer-div' id="wp_tg_cts" style={{display: 'none'}}></div>
)

const runWiderPlanetScript = (user) => {
  window.wptg_tagscript_vars = window.wptg_tagscript_vars || [];
  window.wptg_tagscript_vars.push(function () {
    return {
      wp_hcuid: user?.userId ? user.userId : '',
      ti: '47350',
      ty: 'Home',
      device: navigator.userAgent.match(/ios|iphone|ipad|android/i) ? 'mobile' : 'web',
    };
  });
}

export const getMarketingFooterScript = () => {
  const isProduction = process.env.NODE_ENV === 'production';

  const scriptList = [];
  if (isProduction) {
    scriptList.push(getNaverSearch());
    scriptList.push(getKakaoMomentScript());
    scriptList.push(getEnlipleScript());
    scriptList.push(getWiderPrepareDiv());
    scriptList.push(getWiderPlanetScript());
  }
  return scriptList;
}

export const runMarketingFooterScript = (user) => {
  const isProduction = process.env.NODE_ENV === 'production';

  if (isProduction) {
    runNaverSearch();
    runKakaoMomentScript();
    runWiderPlanetScript(user);
  } else {
    window.wptg_tagscript_vars = window.wptg_tagscript_vars || [];
  }
};
