import { OSType } from './index';

export const searchQueryKeyList: Array<keyof SearchQueryType> = ['osType', 'version', 'qaMode', 'id'];

export type SearchQueryKeyType = typeof searchQueryKeyList[number];
export type SearchQueryType = {
  osType?: OSType,
  version?: string,
  qaMode?: boolean,
  id?: string,
};
