import React, { useEffect } from 'react';
import Head from 'next/head';
import Script from 'next/script';

import { useUserContext } from 'provider';
import {
  getMarketingHeaderScript,
  runMarketingHeaderScript,
  getMarketingFooterScript,
  runMarketingFooterScript,
} from 'utils/Navigation';
import { DefaultHead } from './DefaultHead';

export const Layout: React.FC = ({ children }) => {
  const { userInfo } = useUserContext();
  const isClientSide = typeof window !== 'undefined';

  useEffect(() => {
    if (!isClientSide || !userInfo) return;
    runMarketingHeaderScript(userInfo?.user);
    runMarketingFooterScript(userInfo?.user);
  }, [userInfo, isClientSide]);

  return (
    <>
      <DefaultHead />
      <Head>
        { getMarketingHeaderScript(userInfo) }
      </Head>
      <Script key="json-script" type="application/ld+json">
        { {
          '@context': 'http://schema.org',
          '@type': 'Organization',
          'name': '오늘의집',
          'url': 'https://ohou.se',
          'sameAs': [
            'https://www.instagram.com/todayhouse/?hl=ko',
            'https://www.facebook.com/interiortoday',
            'https://m.post.naver.com/my.nhn?memberNo=202156',
            'https://itunes.apple.com/kr/app/id1008236892',
            'https://play.google.com/store/apps/details?id=net.bucketplace&hl=ko',
            'https://www.youtube.com/channel/UCBKtitA1RwY7F32rCniV1dA/featured',
          ],
        } }
      </Script>
      { children }
      { getMarketingFooterScript() }
    </>
  );
};
